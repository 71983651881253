import styled from 'styled-components';

export const Accordion = styled.div`
  margin-bottom: 5px;
  border: 1px solid #ddd;
`;

export const Question = styled.div`
  font-size: 20px;
  padding: 14px;
  background: ${(props) => (props.isOpen ? '#353535' : '#fff')};
  color: ${(props) => (props.isOpen ? '#fff' : '#353535')};

  &:hover {
    cursor: pointer;
  }
`;

export const Answer = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 20px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
