import styled from 'styled-components';

import { DEVICE } from '../styles/variables';

export const PageTitle = styled.h1`
  margin: 30px 0;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  @media ${DEVICE.minMobile} {
    margin: 60px 0;
    font-size: 40px;
  }
`;

export const ContactInfo = styled.div`
  display: grid;
  font-size: 16px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr;
  margin: 50px 0;
  justify-items: center;
  text-align: center;

  @media ${DEVICE.minMobile} {
    font-size: 22px;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    justify-items: left;
  }
`;

const ContactCommon = styled.div`
  display: grid;
  grid-row-gap: 3px;
`;

export const ContactLogo = styled.div`
  justify-content: center;
  width: 100%;
  display: grid;

  svg {
    width: auto;
    height: 75px;
  }
`;

export const ContactAddress = styled(ContactCommon)`
  font-style: normal;
`;

export const ContactSocial = styled(ContactCommon)`
  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContactNumbers = styled(ContactCommon)``;

export const ContactBankAccount = styled(ContactCommon)``;

export const PiercersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;

  @media ${DEVICE.minMobile} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
