import React, { useState } from 'react';

import { Accordion, Answer, Question } from './styles';

export default ({ question, answer }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <Accordion>
      <Question isOpen={isOpen} onClick={() => toggleOpen(!isOpen)}>
        {question}
      </Question>
      <Answer isOpen={isOpen} dangerouslySetInnerHTML={{ __html: answer }}></Answer>
    </Accordion>
  );
};
