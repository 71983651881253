import React from 'react';
import { graphql } from 'gatsby';
import { withTranslation } from 'react-i18next';

import SEO from '../components/seo';
import Accordion from '../components/Accordion';
import { PageTitle } from '../components/styles/global';

export const QUERY = graphql`
  {
    graphAPI {
      allFaqs {
        id
        question
        answer
        order
        language
      }
    }
  }
`;

const Faq = ({ data: { graphAPI }, i18n }) => {
  const questions = graphAPI.allFaqs ? graphAPI.allFaqs : [];

  return (
    <>
      <SEO title="FAQ" />
      <PageTitle>FAQ</PageTitle>
      {questions
        .filter((question) => question.language.toLowerCase() === i18n.language)
        .map((q, index) => (
          <Accordion key={index} question={q.question} answer={q.answer} />
        ))}
    </>
  );
};

export default withTranslation()(Faq);
